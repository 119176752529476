import AppHttpClient from '../helpers/AppHttpClient.js'

export default class AuthService {
  constructor(httpClient = AppHttpClient.create()) {
    this.httpClient = httpClient
  }

  async loadSpec() {
    const response = await this.httpClient.get('partners_api/apidoc')

    return response.data.data.docs
  }
}
