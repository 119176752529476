import AppHttpClient from '../helpers/AppHttpClient.js'
import AuthenticationFailedError from '../exceptions/authentication/AuthenticationFailedError.js'
import { Buffer } from 'buffer'

export default class AuthService {
  constructor(httpClient = AppHttpClient.create()) {
    this.httpClient = httpClient
  }

  async login(username, password) {
    const response = await this.httpClient.post('partners_api/apidoc/login', {},
    {
      headers: this._requestHeaders(username, password)
    })

    if (response.status === 401) {
      throw new AuthenticationFailedError()
    }

    return response.data.data.token
  }

  _requestHeaders(username, password) {
    return {
      'Authorization': `Basic ${this._basicToken(username, password)}`
    }
  }

  _basicToken(username, password) {
    return Buffer.from(`${username}:${password}`)
                 .toString('base64')
  }
}
