<template>
  <RapidocComponent/>
</template>

<script>
import RapidocComponent from '@/components/Rapidoc.vue'

export default {
  name: 'Docs',
  props: {
    user: String
  },
  components: {
    RapidocComponent
  }
}
</script>
