export const clearStorage = (error) => {
  if (error.response.status === 401) {
    const accessTokenKey = 'access_token'

    if (localStorage.getItem(accessTokenKey)) {
      localStorage.removeItem(accessTokenKey)
      location.reload()
    }
  }

  return Promise.reject(error)
}
