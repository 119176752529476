<template>
  <div id="ba-select">
    Business Account
    <select style="margin-left:14px" @change="switchSelect($event)">
      <option v-for="(credential, index) in credentials" :key="index">
        {{ credential.business_account_slug }}
      </option>
    </select>
  </div>
</template>

<script>
import FetchCredentialsService from '../services/FetchCredentialsService'

export default {
  name: 'CredentialsDropdown',
  data() {
    return {
      credentials: [],
      selected: 0
    }
  },
  methods: {
    async loadCredentials() {
      const credentialsService = new FetchCredentialsService()
      this.$data.credentials = await credentialsService.fetch()

      this.$emit('loaded-credentials', this.$data.credentials)
    },
    async switchSelect(event) {
      const token = this.$data.credentials.find(
        credential => credential.business_account_slug === event.target.value
      ).token

      this.$emit('ba-selected', token)
    }
  }
}
</script>

<style scoped>
  #ba-select {
    display: flex;
  }
</style>
