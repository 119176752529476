<template>
  <mdicon name="logout" @click="logout"/>
</template>

<script>
export default {
  name: 'logout',
  methods: {
    logout() {
      localStorage.clear()
      location.reload()
    }
  }
}
</script>
