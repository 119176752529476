import guards from './guards'
import LoginComponent from '../views/Login.vue'
import DocsComponent from '../views/Docs.vue'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: {
      name: 'docs'
    }
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: (to, from, next) => {
      // Redirect home if the user is already logged in
      if (localStorage.getItem('access_token')) {
        next({ name: 'home' })
        return
      }

      next()
    },
    component: LoginComponent
  },
  {
    path: '/docs',
    name: 'docs',
    component: DocsComponent,
    props: { user: '' },
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

Object.keys(guards).forEach(methodName => {
  guards[methodName].forEach(guard => {
    router[methodName](guard)
  })
})

export default router
