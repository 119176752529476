<template>
  <div id="login">
    <form @submit.prevent="login()">
      <table class="login-table">
        <tbody>
          <tr>
            <td>
              <img id="login-logo" src="https://knokcare.com/img/logo.b3d700fd.svg" />
            </td>
          </tr>
          <tr>
            <td>
              <input
                id="login-username"
                name="username"
                placeholder="Username"
                type="text"
                v-model="input.username"
              />
            </td>
          </tr>
          <tr>
            <td>
              <input
                id="login-password"
                name="password"
                placeholder="Password"
                type="password"
                v-model="input.password"
              />
            </td>
          </tr>
          <tr>
            <td>
              <input
                id="tenant"
                name="tenant"
                placeholder="Tenant name (e.g knok)"
                type="text"
                v-model="input.tenant"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
import AuthService from '../services/AuthService.js'

export default {
  name: 'login',
  data() {
    return {
      input: {
        username: '',
        password: '',
        tenant: ''
      }
    }
  },
  methods: {
    async login() {
      localStorage.tenant = this.input.tenant

      const authService = new AuthService()
      const result = await authService.login(
        this.input.username,
        this.input.password
      )

      localStorage.access_token = result

      this.$emit('authenticated', true)
      this.$router.replace({
        name: 'docs',
        props: { user: this.input.username }
      })
    }
  }
}
</script>

<style scoped>
#login {
  width: 500px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin: auto;
  margin-top: 200px;
  padding: 20px;
  display: flex;
  text-align: center;
}
.login-table {
  border-collapse:separate;
  border-spacing:0 10px;
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
}
td {
  text-align: center;
  vertical-align: middle;
}
</style>
