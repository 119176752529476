import axios from 'axios'
import { clearStorage } from './interceptors/ClearStorageInterceptor'
import { addAuthenticationHeader } from './interceptors/AddAuthenticationHeaderInterceptor'

const defaultConfig = {
  timeout: process.env.VUE_REQUEST_TIMEOUT
}

class AppHttpClient {
  static create() {
    defaultConfig.baseURL = this._buildUrlWithContext()

    const axiosInstance = axios.create(defaultConfig)

    this._setInterceptors(axiosInstance)

    return axiosInstance
  }

  static _setInterceptors(axiosInstance) {
    axiosInstance.interceptors.request.use(config => addAuthenticationHeader(config))
    axiosInstance.interceptors.response.use(response => response, error => clearStorage(error))
  }

  static _buildUrlWithContext() {
    const tenant = window.localStorage.getItem('tenant')

    if (!tenant) {
      return process.env.VUE_APP_BACKEND_URL
    }

    const beUrl = process.env.VUE_APP_BACKEND_URL
    const index = beUrl.indexOf('://') + 3

    return beUrl.slice(0, index) + `${tenant}.` + beUrl.slice(index)
  }
}

export default AppHttpClient
