import AppHttpClient from '../helpers/AppHttpClient.js'

export default class FetchCredentialsService {
  constructor(httpClient = AppHttpClient.create()) {
    this.httpClient = httpClient
  }

  async fetch() {
    const response = await this.httpClient.get('partners_api/apidoc/credentials')

    return response.data.data.collection
  }
}
