// Authentication guard
// meta.requiresAuth: true
export const authenticationGuard = (to, from, next) => {
  const requiresAuth = to.matched.some(
    record => record.meta && record.meta.requiresAuth
  )

  if (requiresAuth && !localStorage.getItem('access_token')) {
    next({ name: 'login' })
    return
  }

  next()
}

// Object in which the key is the guard registration method and the value is an
// array of the guards to be registered for that method.
export default {
  beforeEach: [
    authenticationGuard
  ]
}
