<template>
  <rapi-doc
    allow-server-selection="false"
    allow-spec-url-load="false"
    allow-spec-file-load="false"
    class="rapidoc"
    info-description-headings-in-navbar="true"
    primary-color="#42a1ff"
    ref="rapidoc"
    render-style="focused"
    sort-tags="true"
    use-path-in-nav-bar="false"
  >
    <div slot="logo" class="rapidocLogo">
      <img src="https://knokcare.com/img/logo.b3d700fd.svg" />
    </div>

    <div slot="header" id="docs-header-container">
      <CredentialsDropdown
        @ba-selected="setCredential"
        @loaded-credentials="setDetaultCredential"
        ref="credentials"
      />
      <Logout/>
    </div>
  </rapi-doc>
</template>

<script>
import 'rapidoc'
import LoadSpecService from '../services/LoadSpecService'
import CredentialsDropdown from './CredentialsDropdown'
import Logout from './Logout'

export default {
  name: 'Rapidoc',
  mounted() {
    this.loadSpec()
  },
  components: {
    CredentialsDropdown,
    Logout
  },
  methods: {
    async loadSpec() {
      const loadSpecService = new LoadSpecService()
      const spec = await loadSpecService.loadSpec()

      this.$refs.rapidoc.loadSpec(spec)

      // don't like this but the only way I see to decouple the components
      // in this case would be to introduce a global storage with vuex
      await this.$refs.credentials.loadCredentials()
    },
    setCredential(value) {
      this.$refs.rapidoc.setApiKey('jwt_api_key', value)
    },
    setDetaultCredential(credentials) {
      const token = credentials.find(
        credential => credential.business_account_slug === 'knok'
      ).token

      this.setCredential(token)
    }
  }
}
</script>

<style>
.rapidoc {
  height: 100vh;
  width: 100%;
}

.rapidocLogo {
  text-align: center;
  width: 330px;
}

#docs-header-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

rapi-doc::part(label-header-title) {
  padding: 0px;
}
</style>
